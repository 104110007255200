<!--消费模块--卡片记录-->
<template>
  <div
    id="cardRecords"
    v-loading="loading"
    :element-loading-text="$t('consume.a1')"
  >
    <div class="header">
      <my-headertitle>{{ $t("consume.a24") }}</my-headertitle>
      <div class="queryBar">
        <a-row :gutter="16" type="flex" align="bottom">
          <a-col :span="3">
            <a-input
              v-model="form.personName"
              allow-clear
              :placeholder="$t('consume.a25')"
              :title="$t('consume.a25')"
              v-InputText
              :maxLength="30"
            />
          </a-col>
          <a-col :span="3">
            <a-input
              v-model="form.cardFaceNo"
              allow-clear
              :placeholder="$t('consume.a27')"
              :title="$t('consume.a27')"
              v-Input-NumberText
              :maxLength="30"
            />
          </a-col>
          <a-col :span="3">
            <a-input
              v-model="form.managementCode"
              allow-clear
              :placeholder="$t('consume.a29')"
              :title="$t('consume.a29')"
              v-Input-NumberText
              :maxLength="30"
            />
          </a-col>
          <a-col :span="3">
            <a-input
              v-model="form.consumeReaderName"
              allow-clear
              :placeholder="$t('consume.a31')"
              :title="$t('consume.a31')"
              v-InputText
              :maxLength="30"
            />
          </a-col>
          <a-col :span="3">
            <a-input
              v-model="form.consumeReaderId"
              allow-clear
              :placeholder="$t('consume.a33')"
              :title="$t('consume.a33')"
              v-Input-NumberText
              :maxLength="30"
            />
          </a-col>
          <a-col :span="7">
            <my-date>
              <template v-slot:start>
                <a-date-picker
                  v-model="form.startValue"
                  show-time
                  :disabled-date="disabledStartDate"
                  format="YYYY-MM-DD HH:mm:ss"
                  :placeholder="$t('consume.a35')"
                  :allowClear="false"
                  @change="dateSelection"
                  style="width: 100%"
                />
              </template>
              <template v-slot:end>
                <a-date-picker
                  v-model="form.endValue"
                  show-time
                  :disabled-date="disabledEndDate"
                  format="YYYY-MM-DD HH:mm:ss"
                  :placeholder="$t('consume.a36')"
                  :allowClear="false"
                  style="width: 100%"
                />
              </template>
            </my-date>
          </a-col>
          <a-col :span="2">
            <a-button type="primary" @click="inquire">
              {{ $t("consume.a37") }}
            </a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="main" ref="tablemain" v-table-size:cardRecords="tableSize">
      <my-tabletitle>
        {{ $t("throughrecords.a7") }}
        <template v-slot:name>
          <a-button
            type="primary"
            icon="file-text"
            size="small"
            ghost
            :disabled="data.length == 0"
            @click="csvExport"
            style="margin-right: 20px"
          >
            {{ $t("alarm.a51") }}
          </a-button>
          <a-popover placement="bottomRight">
            <template slot="content">
              <a-row style="width: 350px">
                <a-col
                  v-for="(item, index) in displayContent"
                  :key="index"
                  :span="12"
                >
                  <a-checkbox :disabled="item.disabled" v-model="item.select">
                    {{ item.title }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </template>
            <template slot="title">
              <span>{{ $t("alarm.a50") }}</span>
            </template>
            <a-button type="primary" size="small">
              {{ $t("alarm.a50") }}
            </a-button>
          </a-popover>
        </template>
      </my-tabletitle>
      <a-table
        :columns="column"
        :dataSource="data"
        :scroll="size"
        :pagination="pagination"
        :rowKey="(record) => record.id"
      >
      </a-table>
    </div>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import global from "@/mixins/global.js";
import dateContainer from "../../components/Title/dateContainer";
import { getRecordList,pageList } from "../../api/consume";
import { tableSort, csvDownload, tableTitleDisplay, tableIndexDisplay } from "../../utils/utils";
import moment from "moment";

export default {
  name: "cardRecords",
  mixins: [global],
  data() {
    return {
      startDate: undefined,
      data: [],
      loading: false,
      size: { x: 2400, y: 240 },
      form: {
        personName: "",
        cardFaceNo: "",
        managementCode: "",
        consumeReaderName: "",
        consumeReaderId: "",
        startValue: moment().startOf("day"),
        endValue: moment().endOf("day"),
      },
      columns: [
        {
          id: "1",
          dataIndex: "NO",
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
          align: "center",
        },
        {
          id: "2",
          title: this.$t("consume.a41"),
          dataIndex: "date",
          sorter: (a, b) => {
            return tableSort(a, b, "dateTime");
          },
          width: 180,
          align: "center",
        },
        {
          id: "3",
          title: this.$t("consume.a42"),
          dataIndex: "eventText",
          sorter: (a, b) => {
            return tableSort(a, b, "eventText");
          },
          width: 100,
          align: "center",
        },
        {
          id: "4",
          title: this.$t("consume.a43"),
          dataIndex: "eventMessage",
          sorter: (a, b) => {
            return tableSort(a, b, "eventMessage");
          },
          width: 110,
          align: "center",
        },
        {
          id: "5",
          title: this.$t("consume.a44"),
          dataIndex: "personName",
          sorter: (a, b) => {
            return tableSort(a, b, "personName");
          },
          width: 90,
        },
        {
          id: "6",
          title: this.$t("consume.a45"),
          dataIndex: "managementCode",
          sorter: (a, b) => {
            return tableSort(a, b, "managementCode");
          },
          width: 110,
          align: "center",
        },
        {
          id: "7",
          title: this.$t("consume.a46"),
          dataIndex: "cardFaceNo",
          sorter: (a, b) => {
            return tableSort(a, b, "cardFaceNo");
          },
          width: 120,
          align: "center",
        },
        {
          id: "8",
          title: this.$t("consume.a47"),
          dataIndex: "siteName",
          sorter: (a, b) => {
            return tableSort(a, b, "siteName");
          },
          width: 120,
          align: "center",
        },
        {
          id: "9",
          title: this.$t("consume.a48"),
          dataIndex: "credentialReaderId",
          sorter: (a, b) => {
            return tableSort(a, b, "credentialReaderId");
          },
          width: 110,
          align: "center",
        },
        {
          id: "10",
          title: this.$t("consume.a49"),
          dataIndex: "equipmentName2",
          sorter: (a, b) => {
            return tableSort(a, b, "equipmentName2");
          },
          width: 150,
          align: "center",
        },
        {
          id: "11",
          title: this.$t("consume.a50"),
          dataIndex: "moneySpend",
          sorter: (a, b) => {
            return tableSort(a, b, "moneySpend");
          },
          width: 100,
          align: "center",
        },
        {
          id: "12",
          title: this.$t("consume.a51"),
          dataIndex: "moneyRemain",
          sorter: (a, b) => {
            return tableSort(a, b, "moneyRemain");
          },
          width: 90,
          align: "center",
        },
        {
          id: "13",
          title: this.$t("consume.a52"),
          dataIndex: "dineType",
          sorter: (a, b) => {
            return tableSort(a, b, "dineType");
          },
          width: 90,
          align: "center",
        },
        {
          id: "14",
          title: this.$t("consume.a53"),
          dataIndex: "personGrName1",
          sorter: (a, b) => {
            return tableSort(a, b, "personGrName1");
          },
          align: "center",
        },
        {
          title: this.$t("consume.a54"),
          dataIndex: "personGrName2",
          sorter: (a, b) => {
            return tableSort(a, b, "personGrName2");
          },
          align: "center",
        },
        {
          id: "15",
          title: this.$t("consume.a55"),
          dataIndex: "personGrName3",
          sorter: (a, b) => {
            return tableSort(a, b, "personGrName3");
          },
          align: "center",
        },
        {
          id: "16",
          title: this.$t("consume.a56"),
          dataIndex: "personGrName4",
          sorter: (a, b) => {
            return tableSort(a, b, "personGrName4");
          },
          align: "center",
        },
        {
          id: "17",
          title: this.$t("consume.a57"),
          dataIndex: "personGrName5",
          sorter: (a, b) => {
            return tableSort(a, b, "personGrName5");
          },
          align: "center",
        },
      ],
      column: [],
      displayContent:JSON.parse(localStorage.getItem("cardRecords_tableTitle")) || [],
      pagination: {
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = pageSize;
          if(this.$platform.spider==='NEW'){
            this.inquire();
          }
        },
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
          if(this.$platform.spider==='NEW'){
            this.inquire();
          }
        },
      },
    };
  },
  watch: {
    // 监听表格表头显示内容生成表格表头
    displayContent: {
      handler(newValue) {
        const columns = this.columns;
        const name = "cardRecords_tableTitle";
        const data = tableIndexDisplay(name, columns, newValue);
        this.column = data;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    const name = "cardRecords_tableTitle";
    const columns = this.columns;
    const data = tableTitleDisplay(name, columns);
    if (data.columns == undefined) {
      this.displayContent = data.displayContent;
    } else {
      this.column = data.columns;
      this.displayContent = data.displayContent;
    }
  },
  methods: {
    moment,
    // 条件查询方法
    inquire() {
      this.loading = true
      const startValue = this.form.startValue
      const endValue = this.form.endValue
      if (startValue == null) {
        this.form.startValue = moment().startOf("day")
        this.form.endValue = moment().endOf("day")
      } else if (endValue == null && startValue !== null) {
        const _val = JSON.stringify(this.form.startValue)
        const val = JSON.parse(_val)
        this.form.endValue = moment(val).endOf("day")
      }
      // 表格分页接口请求
        const data = {
          siteId: this.$route.query.id,
          serviceType:13,
          hisType:3,
          pageNum:this.pagination.defaultCurrent,
          pageSize:this.pagination.pageSize,
          personName: this.form.personName,
          cardFaceNo: this.form.cardFaceNo,
          managementCode: this.form.managementCode,
          equipmentName2: this.form.consumeReaderName,
          credentialReaderId: this.form.consumeReaderId,
          startDateTime: this.form.startValue.format("YYYY-MM-DD HH:mm:ss"),
          endDateTime: this.form.endValue.format("YYYY-MM-DD HH:mm:ss"),
        }
        console.log(data)
        pageList(data)
        .then((res) => {
          console.log(res)
          this.loading = false;
          let { data:{ list ,total } } = res;
          this.pagination.total = total;
          this.data = list;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    }, 
    // 表格CSV导出
    csvExport() {
      const date1 = this.form.startValue.format("YYYYMMDD");
      const date2 = this.form.endValue.format("YYYYMMDD");
      const name = this.$t("consume.a24");
      const fileName = `${date1}~${date2}${name}`;
      const data = this.data;
      const columns = this.columns;
      csvDownload(data, columns, fileName);
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-date": dateContainer,
  },
};
</script>

<style scoped>
#cardRecords {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  padding: 0 20px;
  overflow: hidden;
}
.queryBar {
  min-width: 1250px;
  padding: 10px;
  min-width: 850px;
  margin-bottom: 10px;
  background-color: aliceblue;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.main {
  width: 100%;
  height: calc(100% - 114px);
  padding: 0px 20px;
  overflow: hidden;
}
</style>